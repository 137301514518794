import type i18n from '@nuxtjs/i18n/dist/runtime/plugins/i18n'
import type { ComputedRef, Ref } from 'vue'
import { IssuesCollateralType, collateralTypesToDmatChecks } from '@/api/modules/issues/services/getIssuesList/getIssuesList.types'

type ctx = {
  t: typeof i18n.t
}

export function useIssueCollateralScoring(issueCollateralTypes: Ref<IssuesCollateralType[]> | ComputedRef<IssuesCollateralType[]>, { t }: ctx) {
  const uniqId = useUniqueId('collateral-types')
  const checksList = useState<{ type: string, checked: boolean }[]>(uniqId, () => [])

  watch(issueCollateralTypes, (typesList) => {
    checksList.value = (collateralTypesToDmatChecks as Array<IssuesCollateralType[]>).map((types) => {
      const type = types.find(type => typesList.includes(type as IssuesCollateralType))

      let typeName: string = type as string // make string to use as name

      const isPropertyType = types.includes(IssuesCollateralType.movables) || types.includes(IssuesCollateralType.properties)

      if (isPropertyType) {
        typeName = t('bondList.collateralTypes.property').toString()

        types
          .filter(type => typesList.includes(type))
          .forEach((type, idx, arr) => {
            const lowercaseType = type.toLocaleLowerCase()

            typeName += idx === 0 ? ` (${lowercaseType}` : ` ${t('bondList.collateralTypes.propertiesDivider')} ${lowercaseType}`

            if (idx === arr.length - 1) {
              typeName += ')'
            }
          })
      }

      return { type: typeName || types[0], checked: Boolean(type) }
    })
      .sort((a, b) => Number(b.checked) - Number(a.checked))
  }, { immediate: true, deep: true })

  return checksList
}
