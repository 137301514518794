<script lang="ts" setup>
import { CfgHint } from '@cfgtech/ui'
import { useIssueCollateralScoring } from '~/composables/issues/useIssueCollateralScoring'
import { IssuesCollateralType } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { DMAT_CHECKS_COUNT } from '~/constants/issue'
import type { Issue } from '~/api/types'

type Props = {
  inverseUncheckedBg?: boolean
  dmatChecks?: number
  collaterals: Issue['collaterals']
}

const props = defineProps<Props>()

const { t } = useI18n()

const isCollateralsUnknown = computed(() => !props.collaterals.length)

const isCollateralsEmpty = computed(() => props.collaterals.some(({ type }) => type === IssuesCollateralType.none))

const collateralTypes = computed(() => props.collaterals.map(({ type }) => type as IssuesCollateralType))

// Get all checks from server and override types movables and properties to one line
const checksList = useIssueCollateralScoring(collateralTypes, { t })
const hash = Math.random()
</script>

<template>
  <CfgHint v-if="dmatChecks !== undefined" hide-icon>
    <template #trigger>
      <ul class="flex gap-x-[0.35rem]">
        <li
          v-for="(_, index) in Array(DMAT_CHECKS_COUNT)"
          :key="index"
        >
          <IssueScoreItem
            :id="`score-item-${index}-${hash}`"
            :checked="index + 1 <= dmatChecks"
            class="text-[1.2rem]"
            :inverse-unchecked-bg="inverseUncheckedBg"
          />
        </li>
      </ul>
    </template>

    <template #content="{ initialized }">
      <template v-if="initialized">
        <ul v-if="!isCollateralsUnknown && !isCollateralsEmpty" class="space-y-2">
          <li
            v-for="{ checked, type } in checksList"
            :key="type"
            class="flex items-center gap-x-2"
          >
            <IssueScoreItem :checked="checked" />
            {{ type }}
          </li>
        </ul>

        <template v-if="isCollateralsUnknown">
          {{ $t('bondList.collateralTypes.isUnknown') }}
        </template>

        <template v-else-if="isCollateralsEmpty">
          {{ $t('bondList.collateralTypes.empty') }}
        </template>
      </template>
    </template>
  </CfgHint>
</template>
